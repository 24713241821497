import cn from 'classnames';
import {json, type LoaderFunctionArgs} from '@remix-run/cloudflare';
import {useLoaderData} from '@remix-run/react';
import {useEffect, useState} from 'react';
import merge from 'lodash/merge';

import {ColorSchemeEnum, ModalSizeEnum} from '@/enums';
import {baseUri} from '@/constants';
import {generateCanonicalUrl} from '@/utils/server/url';
import {UrlUtils} from '@/utils/UrlUtils';
import {localeImage} from '@/utils/image';
import {pageLoader} from '@/utils/server/loader/pageLoader';
import type {LoaderResponse} from '@/utils/server/request.server';
import {signupUrl, signupName} from '@/hooks/navigation/utils';
import {removeHash, usePushState} from '@/hooks/usePushState';
import HeroFloatingImage from '@/components/base/sections/Hero/HeroFloatingImage';
import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import Card from '@/components/base/modules/Card/Card';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import Conversion from '@/components/base/sections/Conversion/Conversion';
import SideBySideList from '@/components/base/sections/SideBySideList/SideBySideList';
import HorizontalSlideshow from '@/components/pages/shared/HorizontalSlideshow/HorizontalSlideshow';
import Testimonial from '@/components/base/sections/Testimonial/Testimonial';
import Badge from '@/components/base/elements/Badge/Badge';
import MagicIcon from '@/components/base/elements/Icon/animated-icons/Magic/Magic';
import Typography from '@/components/base/elements/Typography/Typography';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import PageLayout from '@/components/shared/Page/PageLayout';
import Modal, {ModalColorScheme} from '@/components/shared/Modal/Modal';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer/WistiaVideoPlayer';

import type {CardPropsCustom, InboxPageContent} from './types';

export {links} from './links';

export const handle = {
  metadata: {
    pageCategory: 'Market',
    pageGroup: 'product',
    pagePath: '/inbox',
  },
};

export const loader = async (args: LoaderFunctionArgs) => {
  const baseData = (await pageLoader(args)) as LoaderResponse;
  const {t} = args.context.i18n;
  const {site} = baseData;

  const settingsJson = await args.context.getPageData('settings.json');
  const {images: settings} = JSON.parse(settingsJson ?? '{}');
  const urlUtils = new UrlUtils(args.context.site);
  const contentPath = `pages${baseData.fileRoutePath}/content`;

  const getResponsiveImage = (image: any) => ({
    ...image,
    src: localeImage<string>(image.src, site),
    srcSet: `${localeImage<string>(image.src, site)} 2x`,
  });

  const localizedLinks = {
    admin: urlUtils.getUrl(settings.links.admin, 'admin'),
    blogWoodToys: urlUtils.getUrl(settings.links.blogWoodToys, 'blog'),
    helpManual: urlUtils.getUrl(settings.links.helpManual, 'help'),
    helpQuestions: urlUtils.getUrl(settings.links.helpQuestions, 'help'),
    deeplinkFreeTrial: signupUrl(
      args.context.site,
      generateCanonicalUrl(args.request.url, baseUri).href,
      {},
      ['paid_trial_experience'],
    ),
  };
  const isEnglishLocale = site.locale.includes('en');

  const isDynamicHero = [
    'de',
    'en',
    'en-CA',
    'en-GB',
    'fr',
    'fr-CA',
    'ja',
  ].includes(site.locale);

  const isDynamicSlide =
    site.locale.startsWith('en') ||
    site.locale.startsWith('fr') ||
    site.locale.startsWith('de') ||
    site.locale === 'ja';

  const content: InboxPageContent = merge(
    {
      hero: t(`${contentPath}:hero`, {
        deeplinkFreeTrial: localizedLinks.deeplinkFreeTrial,
        deeplinkFreeTrialName: signupName,
      }),
      productFeature: t(`${contentPath}:productFeature`),
      checkout: t(`${contentPath}:checkout`),
      midPageConversion: t(`${contentPath}:midPageConversion`),
      empowerYourself: t(`${contentPath}:empowerYourself`),
      testimonial: t(`${contentPath}:testimonial`),
      thePowerToChat: t(`${contentPath}:thePowerToChat`),
      resources: t(`${contentPath}:resources`),
      conversion: t(`${contentPath}:conversion`, {
        deeplinkFreeTrial: localizedLinks.deeplinkFreeTrial,
        deeplinkFreeTrialName: signupName,
      }),
      supportModal: t(`${contentPath}:supportModal`),
    },
    {
      hero: {
        ...settings.hero,
        ctas: {
          ...settings.hero.ctas,
          buttons: [
            {
              href: localizedLinks.admin,
              componentName: 'log-in-to-get-started',
            },
          ],
        },
        images: !isDynamicHero
          ? settings.hero.images.map((image: any) => ({
              ...image,
              ...getResponsiveImage(image),
            }))
          : null,
        videos: isDynamicHero
          ? settings.hero.videos.map((video: any) => ({
              ...video,
              videoId: localeImage(video.videoId, site),
              options: {
                ...video.options,
                aspectRatio: 'aspect-[8/7]',
                image: {
                  ...video.image,
                  srcSet: localeImage(video.options.image.srcSet, site),
                },
              },
            }))
          : null,
      },
      productFeature: {
        ...settings.productFeature,
        slideshow: {
          ...settings.productFeature.slideshow,
          cards: settings.productFeature.slideshow.cards.map((card: any) => ({
            ...card,
            image: getResponsiveImage(card.image),
            video: isDynamicSlide &&
              !!card.video && {
                ...card.video,
                videoId: localeImage(card.video?.videoId, site),
              },
          })),
        },
      },
      checkout: {
        ...settings.checkout,
        image: getResponsiveImage(settings.checkout.image),
      },
      midPageConversion: settings.midPageConversion,
      empowerYourself: {
        ...settings.empowerYourself,
        image: getResponsiveImage(settings.empowerYourself.image),
      },
      testimonial: settings.testimonial,
      thePowerToChat: {
        ...settings.thePowerToChat,
        image: getResponsiveImage(settings.thePowerToChat.image),
      },
      resources: merge(settings.resources, {
        cardGrid: {
          cards: [
            {
              link: {
                href: localizedLinks.helpManual,
              },
              isCardVisible: true,
            },
            {
              link: {
                href: localizedLinks.blogWoodToys,
              },
              isCardVisible: isEnglishLocale,
            },
            {
              link: {
                href: localizedLinks.helpQuestions,
              },
              isCardVisible: true,
            },
          ],
          isEnglishLocale,
        },
      }),
      conversion: {
        ...settings.conversion,
        buttons: [
          {
            ...settings.conversion.buttons[0],
            href: urlUtils.getUrl(settings.links.admin, 'admin'),
          },
        ],
      },
      supportModal: {
        ...settings.supportModal,
        image: getResponsiveImage(settings.supportModal.image),
      },
    },
  );

  if (site.locale !== 'en') {
    content.midPageConversion.buttons = [];
  }

  return json({
    ...baseData,
    content,
    shareImage: localeImage(settings.shareImage, site),
  });
};

export default function Inbox() {
  const {content} = useLoaderData<typeof loader>();
  const {targetMatched} = usePushState({targetHash: '#support-modal'});
  const [scrollPosition, setScrollPosition] = useState(0);

  const {
    hero,
    productFeature,
    checkout,
    midPageConversion,
    empowerYourself,
    testimonial,
    thePowerToChat,
    resources,
    conversion,
    supportModal,
  } = content as InboxPageContent;

  useEffect(() => {
    targetMatched && setScrollPosition(window.scrollY);
  }, [targetMatched]);

  const handleSupportModalClose = () => {
    removeHash();
    window.scrollTo(0, scrollPosition);
  };

  return (
    <PageLayout footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}>
      <HeroFloatingImage
        className={cn(
          '[&_.container>div:last-child]:md:col-span-6',
          '[&_.container>div:last-child]:md:col-start-7',
          '[&_.container>div:last-child_img]:object-contain',
        )}
        colorScheme={ColorSchemeEnum.White}
        sectionName="hero"
        id="hero"
        {...hero}
      />
      <Section
        bottomSpacing="2xl"
        topSpacing="2xl"
        sectionName="how-inbox-works"
        id="how-inbox-works"
      >
        <SectionHeader {...productFeature.sectionHeader} center />
        <HorizontalSlideshow
          {...productFeature.slideshow}
          progressBarClassName="before:bg-black"
        />
      </Section>
      <SideBySideList
        {...checkout}
        className="checkout-gradient"
        sectionProps={{
          bottomSpacing: '2xl',
          topSpacing: '2xl',
          sectionName: 'checkout',
          id: 'checkout',
        }}
      />
      <Section
        mode="dark"
        className="py-3xl"
        sectionName="more-than-customer-support-section"
        id="more-than-customer-support-section"
      >
        <Conversion {...midPageConversion} mode="dark" />
      </Section>
      <SideBySideList
        {...empowerYourself}
        sideBySideItems={empowerYourself.sideBySideItems?.map((item) => ({
          ...item,
          headingGroup: {
            ...item.headingGroup,
            headingHtml: (
              <Typography as="span" size="t5" className="relative">
                {item.headingGroup?.headingHtml}{' '}
                {item.badge && (
                  <Badge
                    mode="dark"
                    className="!m-0 align-middle bg-dragonfruit-10"
                  >
                    {item.badge}
                  </Badge>
                )}
                {item.magicIcon && (
                  <MagicIcon
                    className="absolute ml-3 mt-1"
                    size={25}
                    options={{animationOffset: '0px'}}
                  />
                )}
              </Typography>
            ),
          },
        }))}
        className="empower-yourself-gradient"
        sectionProps={{
          bottomSpacing: '2xl',
          topSpacing: '2xl',
          sectionName: 'empower-yourself',
          id: 'empower-yourself',
        }}
      />
      {testimonial.testimonials.length > 0 && (
        <Section
          bottomSpacing="2xl"
          topSpacing="2xl"
          sectionName="testimonial"
          id="testimonial"
        >
          <Testimonial {...testimonial.testimonials[0]} />
        </Section>
      )}
      <SideBySideList
        {...thePowerToChat}
        className="the-power-to-chat-gradient"
        sectionProps={{
          bottomSpacing: '2xl',
          topSpacing: '2xl',
          sectionName: 'the-power-to-chat',
          id: 'the-power-to-chat',
        }}
      />
      <Section
        className="gap-y-2xl"
        topSpacing="2xl"
        bottomSpacing="2xl"
        sectionName="resources-section"
        id="resources-section"
      >
        <SectionHeader {...content.resources.sectionHeader} />
        <CardGrid
          numberOfColumns={resources.cardGrid.isEnglishLocale ? 3 : 2}
          withContainer
        >
          {resources.cardGrid.cards.map(
            (props: CardPropsCustom, index: number) =>
              props.isCardVisible && (
                <Card
                  {...merge(props, {
                    icon: {
                      className:
                        'icon-gradient-peach h-16 p-4 rounded-[14px] w-16',
                    },
                  })}
                  key={index}
                  size="small"
                  type="outline"
                />
              ),
          )}
        </CardGrid>
      </Section>
      <Section
        topSpacing="2xl"
        bottomSpacing="2xl"
        className="conversion-background"
        sectionName="conversion"
        id="conversion"
      >
        <Conversion {...conversion} />
      </Section>
      <Modal
        handleClose={handleSupportModalClose}
        id="support-modal"
        isActive={targetMatched}
        modalSize={ModalSizeEnum.Full}
        colorScheme={ModalColorScheme.Dark}
        className="bg-black/50"
      >
        <WistiaVideoPlayer {...supportModal} />
      </Modal>
    </PageLayout>
  );
}
