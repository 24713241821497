import type {LinksFunction} from '@remix-run/server-runtime';

import stylesheet from './styles/inbox.css?url';

const links: LinksFunction = () => [
  {
    rel: 'stylesheet',
    href: stylesheet,
  },
];

export {links};
